import React, { Component } from 'react'
import axios from 'axios'
import { DBJSON } from '../../db';

const BASE_URL = "https://my-json-server.typicode.com/lhmisho/React-Tutorial/themeOneFooterSection";

var currentDate = new Date();
    var currentYear = currentDate.getFullYear();

class Footer extends Component{

    state = {
        data: DBJSON.themeOneFooterSection.socialMedia
        
    }
    
    componentDidMount(){
        // axios.get(`${BASE_URL}`)
        //     .then(res => {
        //         this.setState({
        //             data: DBJSON.themeOneFooterSection,
        //             initData: DBJSON.themeOneFooterSection.socialMedia
        //         })
        //         // console.log(this.state)
        //     })
    }

    render(){
        return(
            <footer className="footer-section bg-secondary pt-40">
            <div className="footer-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <div className="footer-single-col text-center">
                                
                                <div className="footer-social-list footer_logo_icons">
                                <img src='/img/colour_logo.svg' alt="" />
                                    <ul className="list-inline">
                                        {this.state.data.map((item, idx) =>{
return (

    <li key={`fo_${idx}`} style={{paddingLeft:"13px"}}>
      <a href={item.link} target="_blank">
        <i className={item.iconClass}></i>
      </a>
    </li>
  );
                                          })}
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>&copy; Copyright {currentYear} - FieldForce-AI 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        )
    }
}

export default Footer