import React, { Component } from "react";
import emailjs from '@emailjs/browser';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.myForm = React.createRef();
  }

  state = {
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
    errors: {
      name: '',
      email: '',
      phone: ''
    }
  };

  validateForm = () => {
    const errors = {};
    let isValid = true;
  
    // Name validation
    if (!this.state.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
  
    // Email validation
    if (!this.state.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(this.state.email)) {
      errors.email = "Invalid email address";
      isValid = false;
    }
  
    // Message validation
    if (!this.state.message.trim()) {
      errors.message = "Message is required";
      isValid = false;
    }
  
    // Phone validation (optional, only if a value is entered)
    if (this.state.phone.trim() && !/^\d{11}$/.test(this.state.phone)) {
      errors.phone = "Invalid phone number (11 digits)";
      isValid = false;
    }
  
    // Update state with errors if any
    this.setState({ errors });
  
    return isValid;
  };
  
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: '',
      },
    });
  };

  sendEmail = (e) => {
    e.preventDefault();
  
    if (this.validateForm()) {
      console.log(this.myForm.current, "Mohsin");
      emailjs.sendForm('service_70d68i8', 'template_xwcmeva', this.myForm.current, '08y1h1kw_EMZz485D')
        .then((result) => {
          console.log(result.text);
          alert("Email sent Successfully");
  
          // Clear the input fields
          this.setState({
            name: '',
            email: '',
            phone: '',
            company: '',
            message: '',
            errors: {
              name: '',
              email: '',
              phone: ''
            }
          });
        }, (error) => {
          console.log(error.text);
          alert("Email Sending Error");
        });
    }
  };
  
  render() {
    return (
      <div>
        
        <form
          ref={this.myForm}
          onSubmit={this.sendEmail}
          className="contact-us-form"
          noValidate
        >
          <h6>Reach us quickly</h6>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${this.state.errors.name ? 'is-invalid' : ''}`}
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  required
                  onChange={this.changeHandler}
                  value={this.state.name}
                />
                <div className="invalid-feedback" style={{ color: 'red' }}>
                  {this.state.errors.name}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="email"
                  className={`form-control ${this.state.errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  required
                  onChange={this.changeHandler}
                  value={this.state.email}
                />
                <div className="invalid-feedback" style={{ color: 'red' }}>
                  {this.state.errors.email}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="tel"
                  className={`form-control ${this.state.errors.phone ? 'is-invalid' : ''}`}
                  id="phone"
                  name="phone"
                  placeholder="Your Phone"
                  onChange={this.changeHandler}
                  value={this.state.phone}
                />
                <div className="invalid-feedback" style={{ color: 'red' }}>
                  {this.state.errors.phone}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  name="company"
                  value={this.state.company}
                  onChange={this.changeHandler}
                  size="40"
                  className="form-control"
                  id="company"
                  placeholder="Your Company"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="7"
                  cols="25"
                  placeholder="Message"
                  onChange={this.changeHandler}
                  value={this.state.message}
                />
                <div className="invalid-feedback" style={{ color: 'red' }}>
                  {this.state.errors.message}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-20">
              <button
                type="submit"
                className="btn softo-solid-btn pull-right"
                id="btnContactUs"
              >
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
