export const DBJSON = {
  "blogDetail": {
    "title": "Continually aggregate interdependent infrastructures with progressive.",
    "author":"Mr. Joy",
    "date": "1 August 2019",
    "image": "img/blog-img2.jpg",
    "qoute": "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Pellentesque suscipit tincidunt magna non mollis. Fusce tempus tincidunt nisi, in luctus elit pellentesque quis. Sed velit mi, ullamcorper ut tempor ut, mattis eu lacus. Morbi rhoncus aliquet tellus, id accumsan enim sollicitudin vitae.",
    "contentOne": "So striking at of to welcomed resolved. Northward by described up household therefore attention. Excellence decisively nay man yet impression for contrasted remarkably. There spoke happy for you are out. Fertile how old address did showing because sitting replied six. Had arose guest visit going off child she new.",
    "contentTwo": "Forfeited you engrossed but gay sometimes explained. Another as studied it to evident. Merry sense given he be arise. Conduct at an replied removal an amongst. Remaining determine few her two cordially admitting old. Sometimes strangers his ourselves her depending you boy. Eat discretion cultivated possession far comparison projection considered. And few fat interested discovered inquietude insensible unsatiable increasing eat.Procuring education on consulted assurance in do. Is sympathize he expression mr no travelling. Preference he he at travelling in resolution.",
    "contentThree": "Unpleasant astonished an diminution up partiality. Noisy an their of meant. Death means up civil do an offer wound of. Called square an in afraid direct. Resolution diminution conviction so mr at unpleasing simplicity no. No it as breakfast up conveying earnestly immediate principle. Him son disposed produced humoured overcame she bachelor improved. Studied however out wishing but inhabit fortune windows.",
    "comments": [
      {
        "id": 1,
        "name": "Lillie J. Yates",
        "date": "Dec 26, 2017 at 15:35",
        "image": "img/t2.jpg",
        "content": "Continually leverage existing distinctive sources after excellent data. Interactively evolve enterprise leadership skills vis-a-vis."
      },
      {
        "id": 2,
        "name": "Todd B. Tucker",
        "date": "Jan 16, 2018 at 04:35",
        "image": "img/t3.jpg",
        "content": "Professionally envisioneer enabled interfaces vis-a-vis plug-and-play supply chains. Competently benchmark real-time value without process-centric."
      }
    ]
  },
  "themeOnePromoSection": {
    "promoData": [
      {
        "id": 1,
        "heading": "Key Opinion Leaders",
        "content": "Your compass when it comes to healthcare strategy is Opinion Tracking Solutions. Scan for engagement drivers, assess physician effectiveness, and refine success tactics.",
        "iconClass": "ti-face-smile"
      },
      {
        "id": 2,
        "heading": "Call Average Tracking",
        "content": "The key to the success of your sales staff is Call Average Tracking. To increase sales performance, track, evaluate, and enhance customer interactions.",
        "iconClass": "ti-vector"
      },
      {
        "id": 3,
        "heading": "Medi Track Module",
        "content": "Use our Doctors Module to strengthen your medical practice. For more efficient patient care, track doctors' locations with accuracy and manage their profiles with ease.",
        "iconClass": "ti-palette"
      },
      {
        "id": 4,
        "heading": "Product Management",
        "content": "Utilize our technology to optimize your product management, making ordering, tracking, and inventory control easier for smooth supply chain operations.",
        "iconClass": "ti-headphone-alt"
      }
    ]
  },
  "themeOneFeatureSection": {
    // "content": "Continually network virtual strategic theme areas vis-a-vis ubiquitous potentialities. Holisticly negotiate focused e-tailers without premium solutions.",
    "heading": "FieldForce Features",
    "image": "/img/feature-image.png",
    "featureDataOne": [
      {
        "id": 1,
        "iconClass": "fa fa-object-ungroup",
        "heading": "Creative Tasks",
        "content": "Tasks with clear instructions and deadlines are created by administrators."
      },
      {
        "id": 2,
        "iconClass": "fa fa-dropbox",
        "heading": "Assign Tasks",
        "content": "Depending on their location, expertise, or availability, assign tasks to field agents."
      },
      {
        "id": 3,
        "iconClass": "fa fa-smile-o",
        "heading": "Track Progress",
        "content": "Track the status of tasks, real-time location updates, and field agent performance metrics. "
      }
    ],
    "featureDataTwo": [
      {
        "id": 1,
        "iconClass": "fa fa-file-archive-o",
        "heading": "AI Sales Prediction",
        "content": "Anticipate market trends and make appropriate plans by utilizing AI-powered sales prediction."
      },
      {
        "id": 2,
        "iconClass": "fa fa-adjust",
        "heading": "Employee Management",
        "content": "Use Field Force to create, update, and manage employee profiles with ease."
      },
      {
        "id": 3,
        "iconClass": "fa fa-smile-o",
        "heading": "Stock Management",
        "content": "Use Field Force to improve stock control at your pharmacy. Eliminate worries about drug shortages by automating inventory checks."
      }
    ]
  },
  "themeOnePricingSection": {
    "heading": "Pricing Plan",
    "content": "Find the best strategy to propel your company's expansion. Our price selections have been thoughtfully designed with businesses in mind.",
    "pricingData": [
      {
        "id": 1,
        "heading": "Basic",
        "priceType": "Individual",
        "minPrice": "7.",
        "subPrice": "00",
        "packageTime": "Month"
      },
      {
        "id": 2,
        "heading": "Premium",
        "priceType": "Business",
        "minPrice": "15.",
        "subPrice": "00",
        "packageTime": "Month"
      },
      {
        "id": 3,
        "heading": "Ultimate",
        "priceType": "Enterprise",
        "minPrice": "23.",
        "subPrice": "00",
        "packageTime": "Month"
      }
    ]
  },
  "themeOneFaqSection": {
    "heading": "FAQs",
    // "content": "we believe in making simple and fair pricing plans. Choose your next successful planand get started today!.",
    "image": "/img/faq_front.png",
    "data": [
      {
        "id": 1,
        "question": "How can I use FieldForce-AI to assign tasks to field agents? ",
        "answer": "FieldForce-AI's user-friendly interface makes it simple to give tasks to field workers. By streamlining the job assignment process, the software makes sure that duties are assigned and carried out correctly. "
      },
      {
        "id": 2,
        "question": "How important is FieldForce-AI's sales prediction feature?",
        "answer": "Using past data, the sales prediction functionality applies AI to provide precise sales projections. This gives companies the ability to plan ahead, maximize sales tactics, and more wisely distribute resources for higher overall performance. "
      },
      {
        "id": 3,
        "question": "How does FieldForce-AI help in tracking field agents and their tasks?  ",
        "answer": "Field Force-AI lets you track field agents in real-time, so you can keep an eye on their whereabouts, task progress, and status updates instantly. Task management and performance monitoring are improved by this visibility. "
      },
      {
        "id": 4,
        "question": "How does FieldForce-AI ensure the security and privacy of sensitive data, such as sales predictions and task tracking information? ",
        "answer": "Field Assist.AI puts data security first and abides by tight privacy regulations. The software uses strong security features to secure sensitive data, guaranteeing the privacy and security of your information."
      }
    ]
  },
  "themeOneDownloadSection": {
    "heading": "Get The App Now !",
    "content": "FieldForce-AI io maximize efficiency and sales achievement.",
    "image": "/img/iPhone_login.png",
    "data": [
      // {
      //   "id": 1,
      //   "heaing": "Download On",
      //   "category": "App Store",
      //   "iconClass": "ti-apple",
      //   "extraaClass": "hover-active"
      // },
      {
        "id": 2,
        "heaing": "Git It On",
        "category": "Play Store",
        "iconClass": "ti-android",
        "extraaClass": "",
        "link":"https://play.google.com/store/apps/details?id=com.fieldforce.ai&pcampaignid=web_share"
      },
      // {
      //   "id": 3,
      //   "heaing": "Click here for",
      //   "category": "Web",
      //   "iconClass": "fa fa-desktop",
      //   "extraaClass": "",
      //   "link":"https://field-force.azurewebsites.net/"
      // }
    ]
  },
  "themeOneContactSection": {
    "heading": "Contact with us",
    "content": "With our professional advice, experience rapid business growth. To benefit from our expertise, promote innovation, and confidently accomplish your objectives, get in touch with us right away.",
    "branch": "Head Office",
    "address": " 16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex USA",
    "contact": "++1 (302) 321-4914",
    "email": "connect@bigentities.com"
  },
  "themeOneFooterSection": {
    "image": "/img/logo-color.png",
    "socialMedia": [
      {
        "id": 1,
        "iconClass": "fa fa-facebook",
        "link": "https://www.linkedin.com/products/bigentities-field-force/"
      },
      {
        "id": 2,
        "iconClass": "fa fa-twitter",
        "link": "https://www.linkedin.com/products/bigentities-field-force/"
      },
      {
        "id": 3,
        "iconClass": "fa fa-linkedin",
        "link": "https://www.linkedin.com/products/bigentities-field-force/"
      },
      // {
      //   "id": 4,
      //   "iconClass": "fa fa-google-plus",
      //   "link": "google-plus"
      // },
      // {
      //   "id": 5,
      //   "iconClass": "fa fa-youtube",
      //   "link": "youtube"
      // }
    ]
  },
  "themeThreeFeatureSection": {
    "heading": "ApeTech Feature Overview",
    "content": "ApeTech is a comprehensive, secured and compatible App solution for an organization. This is a web based solution for accounting, inventory, sale, purchase and party management.",
    "image": "/img/waves-shape.svg",
    "featureData": [
      {
        "id": 1,
        "featureName": "Ios",
        "featureHeading": "Apple Store",
        "featureImage": "/img/tab-image-1.png",
        "content": "Dramatically re-engineer optimal e-markets vis-a-vis top-line web services. Globally drive 24/7 meta-services with adaptive products. Professionally matrix leading-edge experiences after high-quality networks. Appropriately extend 24/7 customer service before unique communities. Intrinsicly harness resource sucking channels and virtual materials.",
        "ul_1": "Scheduling Big",
        "ul_2": "Data Online Consultations",
        "ul_3": "Live 24/7 Support",
        "ul_4": "Identification & Smart"
      },
      {
        "id": 2,
        "featureName": "Android",
        "featureHeading": "Android Apps on Google Play",
        "featureImage": "/img/tab-image-2.png",
        "content": "Dramatically re-engineer optimal e-markets vis-a-vis top-line web services. Globally drive 24/7 meta-services with adaptive products. Professionally matrix leading-edge experiences after high-quality networks. Appropriately extend 24/7 customer service before unique communities. Intrinsicly harness resource sucking channels and virtual materials.",
        "ul_1": "Scheduling Big",
        "ul_2": "Data Online Consultations",
        "ul_3": "Live 24/7 Support",
        "ul_4": "Identification & Smart"
      },
      {
        "id": 3,
        "featureName": "Microsoft",
        "featureHeading": "Windows Apps - Microsoft Store",
        "featureImage": "/img/tab-image-3.png",
        "content": "Dramatically re-engineer optimal e-markets vis-a-vis top-line web services. Globally drive 24/7 meta-services with adaptive products. Professionally matrix leading-edge experiences after high-quality networks. Appropriately extend 24/7 customer service before unique communities. Intrinsicly harness resource sucking channels and virtual materials.",
        "ul_1": "Scheduling Big",
        "ul_2": "Data Online Consultations",
        "ul_3": "Live 24/7 Support",
        "ul_4": "Identification & Smart"
      }
    ]
  },
  "blogSection": [
    {
      "id": 1,
      "heading": "Continually reinvent competitive",
      "image": "/img/blog-img1.jpg",
      "content": "Rapidiously aggregate client-focused strategic theme areas after high-quality data. Continually aggregate diverse ideas."
    },
    {
      "id": 2,
      "heading": "Competently impact strategic",
      "image": "/img/blog-img2.jpg",
      "content": "Professionally grow technically sound technologies with fully researched metrics. Assertively embrace empowered."
    },
    {
      "id": 3,
      "heading": "Continually reinvent competitive",
      "image": "/img/blog-img1.jpg",
      "content": "Rapidiously aggregate client-focused strategic theme areas after high-quality data. Continually aggregate diverse ideas."
    },
    {
      "id": 4,
      "heading": "Rapidiously predominatev just",
      "image": "/img/blog-img3.jpg",
      "content": "Continually fabricate magnetic interfaces through inexpensive solutions. Continually iterate distributed infomediaries."
    },
    {
      "id": 5,
      "heading": "Interactively productize low-risk",
      "image": "/img/blog-img4.jpg",
      "content": "Appropriately develop high-payoff catalysts for change after premier e-markets. This is interactively maximize."
    },
    {
      "id": 6,
      "heading": "Continually reinvent competitive",
      "image": "/img/blog-img1.jpg",
      "content": "Rapidiously aggregate client-focused strategic theme areas after high-quality data. Continually aggregate diverse ideas."
    },
    {
      "id": 7,
      "heading": "Competently impact strategic",
      "image": "/img/blog-img2.jpg",
      "content": "Professionally grow technically sound technologies with fully researched metrics. Assertively embrace empowered."
    },
    {
      "id": 8,
      "heading": "Continually reinvent competitive",
      "image": "/img/blog-img1.jpg",
      "content": "Rapidiously aggregate client-focused strategic theme areas after high-quality data. Continually aggregate diverse ideas."
    },
    {
      "id": 9,
      "heading": "Rapidiously predominatev just",
      "image": "/img/blog-img3.jpg",
      "content": "Continually fabricate magnetic interfaces through inexpensive solutions. Continually iterate distributed infomediaries."
    },
    {
      "id": 10,
      "heading": "Interactively productize low-risk",
      "image": "/img/blog-img4.jpg",
      "content": "Appropriately develop high-payoff catalysts for change after premier e-markets. This is interactively maximize."
    },
    {
      "id": 11,
      "heading": "Continually reinvent competitive",
      "image": "/img/blog-img1.jpg",
      "content": "Rapidiously aggregate client-focused strategic theme areas after high-quality data. Continually aggregate diverse ideas."
    },
    {
      "id": 12,
      "heading": "Competently impact strategic",
      "image": "/img/blog-img2.jpg",
      "content": "Professionally grow technically sound technologies with fully researched metrics. Assertively embrace empowered."
    }
  ]
}